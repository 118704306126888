<template>
  <div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          :total-items="totalItems"
          :headers="headers"
          :items="students"
          :page="page"
          :search="search"
          :classes="classes"
          :no-add-button="noAddButton"
          :filter-class="filterClass"
          :total-pages.sync="totalPages"
          :is-loading="isLoadingData"
          title="Data Siswa"
          subtitle="Halaman untuk melihat seluruh total siswa "
          @change-page="paginationHandler"
          @live-search="searchHandler"
          @filter-classes="handleFilterClass"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else></SkeletonLoaderTable>
    <ImageOverlay
      :visible="imageOverlay"
      :width="width"
      @close="imageOverlay = false"
    >
      <template v-slot:body>
        <v-card>
          <v-btn
            class="close-image"
            color="white"
            icon
            @click="closeImage"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
          <v-img
            :src="student.image"
            transition="scroll-y-transition"
            min-width="100"
            max-width="700"
          ></v-img>
        </v-card>
      </template>
    </ImageOverlay>
  </div>
</template>

<script>
import { mdiClose } from '@mdi/js'
import DataTablePagination from '../../components/DataTablePagination.vue'
import ImageOverlay from '../../components/ImageOverlay.vue'
import MainCard from '../../components/MainCard.vue'
import SkeletonLoaderTable from '../../components/SkeletonLoaderTable.vue'

export default {
  name: 'StudentHeadMaster',
  components: {
    ImageOverlay,
    DataTablePagination,
    MainCard,
    SkeletonLoaderTable,
  },
  data() {
    return {
      icons: {
        mdiClose,
      },
      student: {
        image: null,
      },
      imageOverlay: false,
      width: 600,
      dialog: '',
      search: '',
      modalDialog: false,
      confirmDialog: false,
      isLoadingTable: true,
      isLoadingData: false,
      isLoadingButton: false,
      noAddButton: true,
      filterClass: true,
      page: 1,
      totalPages: 1,
      totalItems: 1,
      headers: [
        { text: 'No', value: 'index' },
        { text: 'NIS', value: 'nis' },
        { text: 'Nama Lengkap', value: 'name' },
        { text: 'Username', value: 'username' },
        { text: 'Jenis Kelamin', value: 'gender' },
        { text: 'Kelas', value: 'class[0].name' },
        { text: 'Foto', value: 'image' },
      ],
      students: [],
      classes: [],
    }
  },
  watch: {
    page: {
      handler() {
        this.listStudent()
      },
    },
  },
  mounted() {
    this.getSchoolUuid()
    this.listStudent()
    this.listClass()
  },

  methods: {
    handleFilterClass(kelas) {
      if (kelas !== null) return this.listStudent({ class: kelas })

      return this.listStudent()
    },

    getSchoolUuid() {
      this.school = JSON.parse(localStorage.getItem('user')).user.details
      this.school.forEach(el => {
        if (el.school === undefined) {
          this.school_uuid = el.uuid

          return
        }

        if (el.school === null) {
          return
        }
        this.user_uuid = JSON.parse(localStorage.getItem('user')).user.uuid
        this.school_uuid = el.school.uuid
      })

      return true
    },

    async listStudent(params = {}) {
      this.isLoadingData = true
      await this.$services.ApiServices.list('studentheadmaster', {
        ...params,
        school_uuid: this.school_uuid,
        page: this.page,
      }).then(
        ({ data }) => {
          this.students = data.data.map((students, index) => ({
            ...students,
            index: index + 1,
          }))
          this.totalItems = data.meta.total
          this.totalPages = data.meta.last_page
          this.isLoadingData = false
        },
        err => console.error(err),
      )
      this.isLoadingTable = false
      this.isLoadingData = false
    },
    paginationHandler(pageNumber) {
      this.page = pageNumber
    },

    async listClass() {
      await this.$services.ApiServices.list('class', { per_page: 'all', school_uuid: this.school_uuid }).then(
        ({ data }) => {
          this.classes = data.data
        },
        err => console.error(err),
      )
    },

    async searchHandler(data) {
      this.listStudent({ search: data })
    },
    async showImage(uuid) {
      await this.$services.ApiServices.get('greeting', uuid).then(
        ({ data }) => {
          this.student = data.data
          this.student.image = data.data.image
        },
        err => console.error(err),
      )
      this.imageOverlay = true
    },

    closeImage() {
      this.overlay = false
      this.imageOverlay = false
    },
  },
}
</script>

<style>
</style>
